import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CostCenterSubscriptions from "@/views/costCenters/CostCenterSubscriptions.vue";
import {
  CostCenter,
  InterfaceCostCenter,
  InterfaceCostCenterServicesResult,
  InterfaceService,
  emptyCostCenter,
} from "@/models/CostCenter.model";
import { Alert } from "@/models/Alert.model";
import CostCenterLineChart from "@/views/costCenters/CostCenterLineChart.vue";
import moment from "moment";
import { PageData } from "@/models/PageData.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";

const costCenters = namespace("CostCenters");
const organizations = namespace("Organizations");

interface FetchParams {
  search: string;
  page: number;
}

interface MoveCostCenterParams {
  costCenterId: string;
  organizationId: string;
}

interface Service {
  serviceName: string;
  serviceType: string;
  serviceTypeDescription: string;
  sourceCostCenter: string;
  cost: number;
  billingMonth: string;
}

@Component({
  components: {
    CostCenterSubscriptions,
    CostCenterLineChart,
    ServerSideAutocomplete,
  },
})
export default class CostCenterDetails extends Vue {
  @Ref()
  changeCostCenterOrganizationFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  startDate = new Date(new Date().setMonth(new Date().getMonth() - 2))
    .toISOString()
    .slice(0, 7);
  endDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .slice(0, 7);
  public selectedStartDate = new Date(
    new Date().setMonth(new Date().getMonth() - 2)
  )
    .toISOString()
    .slice(0, 7);
  public selectedEndDate = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  )
    .toISOString()
    .slice(0, 7);
  public endDateMenu = false;
  public items: Service[] = [];
  public rangeDate: string[] = [];
  public startDateMenu = false;
  public changeCostCenterOrganizationForm = false;
  public costCenter: InterfaceCostCenter = emptyCostCenter();
  public operationInProgress = false;
  public selectedOrganizationId = "";
  public showSharedResources = 0;

  @costCenters.State
  public selectedCostCenter!: InterfaceCostCenter;

  @costCenters.State
  public costCenterServices!: InterfaceCostCenterServicesResult[];

  @costCenters.State
  public costCenterDetailAlert!: Alert;

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.State
  public organizationsSelectList!: InterfaceOrganization[];

  @costCenters.Mutation
  public setBillingMonth!: (data: string) => void;

  @costCenters.Mutation
  public setRangeDate!: (data: string[]) => void;

  @costCenters.Mutation
  public clearCostCenterDetailAlert!: () => void;

  @costCenters.Action
  public getCostCenter!: (id: string) => Promise<boolean>;

  @costCenters.Action
  public getCostCenterServices!: (id: string) => Promise<boolean>;

  @costCenters.Action
  public changeCostCenterOrganization!: (
    moveOrgParams: MoveCostCenterParams
  ) => Promise<boolean>;

  @organizations.Action
  public getOrganizationsSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @organizations.State
  public organizationAutocompleteLoadMore!: boolean;

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid Subscription Name. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
  };

  async mounted(): Promise<void> {
    if (await this.getCostCenter(this.$router.currentRoute.params.id)) {
      this.setRangeDate(this.getDatesSelected());
      await this.getCostCenterServices(this.selectedCostCenter.name);
      this.items = await this.existingServiceForCost(this.showSharedResources);
    }
  }

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const serviceName = [
      {
        text: "Service Name",
        align: "start",
        value: "serviceName",
        sortable: false,
      },
    ];
    const serviceType = [
      {
        text: "Service Type",
        align: "start",
        value: "serviceType",
        sortable: false,
      },
    ];
    const sourceCostCenter = [
      {
        text: "Source Cost Center",
        align: "start",
        value: "sourceCostCenter",
        sortable: false,
      },
    ];
    const cost = [
      {
        text: "Cost",
        align: "start",
        value: "cost",
        sortable: false,
      },
    ];
    const billingMonth = [
      {
        text: "Billing Month",
        align: "start",
        value: "billingMonth",
        sortable: false,
      },
    ];

    return serviceName.concat(
      serviceType,
      sourceCostCenter,
      cost,
      billingMonth
    );
  }

  cancelStartDate(): void {
    this.startDateMenu = false;
    this.selectedStartDate = this.startDate;
  }

  selectStartDate(): void {
    this.startDateMenu = false;
    this.startDate = this.selectedStartDate;
  }

  minEndDate(): string {
    return this.startDate;
  }

  selectEndDate(): void {
    this.endDateMenu = false;
    this.endDate = this.selectedEndDate;
  }

  cancelEndDate(): void {
    this.endDateMenu = false;
    this.selectedEndDate = this.endDate;
  }

  getDatesSelected(): string[] {
    const dates: string[] = [];
    if (this.startDate && this.endDate) {
      const startDate: moment.Moment = moment(this.startDate).startOf("month");
      const endDate: moment.Moment = moment(this.endDate).endOf("month");
      const currentDate: moment.Moment = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.format("YYYY-MM"));
        currentDate.add(1, "month");
      }
    }
    return dates;
  }

  toggleSharedResourcesView(): void {
    this.update();
  }

  async existingServiceForCost(
    showSharedResources: number
  ): Promise<Service[]> {
    const dates: string[] = [];
    const services: Service[] = [];

    this.costCenterServices.sort(
      (a, b) =>
        new Date(a.billingMonth).getTime() - new Date(b.billingMonth).getTime()
    );

    this.costCenterServices.forEach(
      (value: InterfaceCostCenterServicesResult) => {
        dates.push(value.billingMonth);
        if (value.services.length > 0) {
          value.services.forEach((service: InterfaceService) => {
            if (
              showSharedResources !== 1 ||
              this.selectedCostCenter.name !==
                service.sharedResourceCostCenterId
            ) {
              const serviceElement = {
                serviceName: service.serviceName,
                serviceType: service.serviceType,
                serviceTypeDescription: service.serviceTypeDescription,
                sourceCostCenter: service.sourceCostCenter,
                cost: service.cost,
                billingMonth: value.billingMonth,
              };
              services.push(serviceElement);
            }
          });
        }
      }
    );

    return services;
  }

  public reload(): void {
    window.location.reload();
  }
  @Watch("startDate", {
    deep: true,
  })
  endDateUpdate(): void {
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.endDate = this.startDate;
    }
  }

  getTotalCost(): number {
    return this.items.reduce((total, item) => total + item.cost, 0);
  }

  @Watch("startDate", {
    deep: true,
  })
  @Watch("endDate", {
    deep: true,
  })
  async update(): Promise<void> {
    this.setRangeDate(this.getDatesSelected());
    await this.getCostCenterServices(this.selectedCostCenter.name);
    this.items = await this.existingServiceForCost(this.showSharedResources);
  }

  public readCostCenter(costCenter: InterfaceCostCenter): void {
    this.costCenter = new CostCenter(
      costCenter.id,
      costCenter.name,
      costCenter.responsibleId,
      costCenter.responsibleEmail,
      costCenter.organizationId,
      costCenter.organizationName,
      costCenter.notificationUsers
    );
  }

  public changeOrganizationDialog(): void {
    this.readCostCenter(this.selectedCostCenter);
    this.changeCostCenterOrganizationForm = true;
  }

  public closeChangeOrganizationDialog(): void {
    this.costCenter = emptyCostCenter();
    this.changeCostCenterOrganizationForm = false;
    this.clearCostCenterDetailAlert();
  }

  public setSelectedCostCenterOrganizationId(): void {
    if (
      this.costCenter.organizationId != undefined ||
      this.costCenter.organizationId != null
    ) {
      this.selectedOrganizationId = this.selectedCostCenter.organizationId;
    }
  }

  public async fetchCreateCostCenterOrganizationSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataOrganizations.pageIndex = page;
    await this.getOrganizationsSelectList({
      search: search,
      page: page,
    });
    return this.organizationsSelectList;
  }

  public async changeCostCenterOrganizationDialogAction(
    costCenter: InterfaceCostCenter
  ): Promise<void> {
    if (this.changeCostCenterOrganizationFormElement.validate()) {
      this.operationInProgress = true;
      if (
        await this.changeCostCenterOrganization({
          costCenterId: this.selectedCostCenter.id,
          organizationId: this.costCenter.organizationId,
        })
      ) {
        this.closeChangeOrganizationDialog();
      }
      this.operationInProgress = false;
      this.loadCostCenter(costCenter.id);
    }
  }

  private loadCostCenter(id: string) {
    this.getCostCenter(id);
  }
}
